(function ($) {
  'use strict'


  // ios touch-fix
  $('.nav-main-item').click(function () {
    $(this).focus()
  })


  jQuery('.tx-indexedsearch-browsebox  > li > a').each(function () {
    var $element = $(this)
    if ($element.text() === 'weiter') {
      $element.html('<b class="pagebrowser-prev-next pagebrowser-next arrow-small-cyan-right">weiter</b>')
    }
    if ($element.text() === 'zurück') {
      $element.html('<b class="pagebrowser-prev-next pagebrowser-prev arrow-small-cyan-left">zurück</b>')
    }
  })

})(jQuery)

